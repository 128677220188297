import { Box, Divider, Grid, Typography } from '@mui/material';
import ContentPanel from '../../layout/ContentPanel';

const Schedule = () => (
  <ContentPanel>
    <Box display="flex" flexDirection="column" alignItems="center" px={2}>
      <Box maxWidth={{ xs: '100%', sm: 600 }} minWidth={{ xs: 'auto', md: 600 }}>
        <Box textAlign="center" mt={2} mb={4}>
          <Typography variant="h4">Wedding Day</Typography>
          <Typography variant="h6">September 6, 2024</Typography>
        </Box>
        <Grid container spacing={4} mb={4}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" textAlign="right">
              5:15 PM - 5:45 PM
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6">Ceremony</Typography>
            <Typography>
              John MacInnes Student Ice Arena
              <br />
              600 MacInnes Drive
              <br />
              Houghton, Michigan 49931
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography maxWidth={500}>
              Ceremony will begin <em>promptly</em> at 5:15pm, we ask that you arrive around / before 5:00pm so everyone can
              be seated on time.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" textAlign="right">
              6:00 PM - 7:00 PM
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6">Cocktail Hour</Typography>
            <Typography>
              Dee Stadium
              <br />
              700 E Lakeshore Drive
              <br />
              Houghton, MI 49931
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" textAlign="right">
              7:00 PM - 12:00 AM
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6">Reception</Typography>
            <Typography>
              Dee Stadium
              <br />
              700 E Lakeshore Drive
              <br />
              Houghton, MI 49931
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Box>
    </Box>
  </ContentPanel>
);

export default Schedule;
