import { Box, Grid, Typography } from '@mui/material';
import ContentPanel from '../../layout/ContentPanel';
import HockeySticksPuckImage from '../../shared/HockeySticksPuckImage';
import { useMemo } from 'react';
import { awsLink } from '../Gallery/photos';

const PartyMember = ({ member, reverse, children }) => {
  const memberInfoLookup = () => {
    switch (member) {
      case 'veronica':
        return {
          image: awsLink('party/veronica.jpg', 600, 600),
          name: 'Veronica Tabor',
          header: 'Maid of Honor',
          description:
            'Veronica and Britta met because they both needed a roommate! What neither expected was a wonderful friendship that has followed them around the country. Or the many fun memories of their college house, Boppity Swag, and a tradition of eating at Pizza Hut after finals were over. Veronica is a Mu Beta Psi Fraternity Brother of Britta’s.',
        };
      case 'dani':
        return {
          image: awsLink('party/dani.jpg', 600, 600),
          name: 'Danielle Booms',
          header: 'Bridesmaid',
          description: `While Dani and Britta "officially" met on a pep band trip in 2011 for the NCAA women's basketball championship (there is a photo to prove it of Dani braiding Britta's hair), the two became closer when Britta joined Mu Beta Psi and Dani was her Big Brother. Bonus, Dani and Brandon were housemates at Tech before Dani and Britta really became friends.`,
        };
      case 'raven':
        return {
          image: awsLink('party/raven.jpg', 600, 600),
          name: 'Raven Rebb',
          header: 'Bridesmaid',
          description: `Although Raven and Britta attended Tech at the same time, the pair met through Brandon, as Raven was a work colleague at the time. Raven became a part of the game night crew and bonded with Britta over cats, crafts, and board games. Britta and Raven may obsess over new stationary releases and add many projects to their craft piles, but they try to keep each other accountable for actually using the beautiful art supplies!`,
        };
      case 'stephanie':
        return {
          image: awsLink('party/stephanie.jpg', 600, 600),
          name: 'Stephanie Haselhuhn',
          header: 'Bridesmaid',
          description: `Stephanie and Britta met through the pep band and the pep band fueled their natural tendencies for fun and wacky. From sliding together in mud for oozeball to driving through snow storms with broken middle fingers for basketball tournaments and Boppity Swag house shenanigan Olympics to making up their own words like "saguatter", these two have run the gamut of life experiences. And admiring cobblestones in Stockholm, which was our codeword for a hot guy.`,
        };
      case 'juliann':
        return {
          image: awsLink('party/juliann.jpg', 600, 600),
          name: 'Juliann Raikes',
          header: 'Bridesmaid',
          description: `When Brandon first introduced Britta to his family and was describing his cousins, he had said that Britta and Juliann would be like two peas in a pod. Both are analytical and logical engineers but have a heavy creative side and love books. They could be that Office meme of "it's the same picture".`,
        };
      case 'pat':
        return {
          image: awsLink('party/pat.jpg', 600, 600),
          name: 'Patrick Koro',
          header: 'Best Man',
          description: `Planes, trains, and automobiles; these two have bonded over modes of transportation. And a love of hockey. While they met after college through Britta, it's as if they've been friends for decades. They can often be found flying planes all around the country, or racing cars for many, many hours...in a video game. They competed in both 12 hours of Sebring and 24 hours of Daytona in iRacing.`,
        };
      case 'brad':
        return {
          image: awsLink('party/brad.jpg', 600, 600),
          name: 'Brad Nedrow',
          header: 'Groomsman',
          description:
            'Brad and Brandon became friends during the lean years of Tech hockey in the late 2000s/early 2010s. They played hockey together during their time at Tech at the Dee very late in the night, ice time started at 10:45pm. It was through Brad and his wife, Gina, that Brandon and Britta met!',
        };
      case 'phil':
        return {
          image: awsLink('party/phil.jpg', 600, 600),
          name: 'Phil DePetro',
          header: 'Groomsman',
          description:
            'Phil is a minor legend within Pep Band lore. He overlapped with both Britta and Brandon at Tech and often could be seen at hockey games with either of them all over the country. Phil was a part of the game night crew and provided a level of chaos to game night.',
        };
      case 'adam':
        return {
          image: awsLink('party/adam.jpg', 600, 600),
          name: 'Adam Mitteer',
          header: 'Groomsman',
          description: `Adam and Brandon met through Britta's role on the Alumni Board. These two can talk about everything and anything for hours, including real estate, business, and beer, even though Adam can be a man of few words. While Adam and Brandon did not overlap years at Tech, they've become good friends.`,
        };
      case 'dennis':
        return {
          image: awsLink('party/dennis.jpg', 600, 600),
          name: 'Dennis Gruetzmacher',
          header: 'Groomsman',
          description: `Brandon first met Dennis when he started to date his cousin, Nicole. Instead of attending the 2017 WCHA championship game (the double overtime win against BGSU where the arena went wild with the win), Brandon attended Dennis and Nicole's wedding. Since then, Dennis has been a fun and perfect addition to the Leny family.`,
        };
      default:
        return null;
    }
  };

  const memberInfo = useMemo(memberInfoLookup, [member]);

  return memberInfo ? (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', xl: reverse ? 'row-reverse' : 'row' }}
      alignItems={{ xs: 'center', xl: 'start' }}
      minHeight={{ xs: 'auto', md: 400, xl: 'auto' }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" width={175}>
        {memberInfo.image ? (
          <Box
            mb={1}
            width={150}
            height={150}
            sx={{ '& > img': { borderRadius: '50%', borderWidth: 1, borderStyle: 'solid', borderColor: 'primary.main' } }}
          >
            <img src={memberInfo.image} alt={member} width="100%" height="100%" />
          </Box>
        ) : null}
        <Typography>{memberInfo.name}</Typography>
        <Typography variant="body2">{memberInfo.header}</Typography>
      </Box>
      <Box px={{ xs: 0, xl: 2 }} maxWidth={450} mt={1}>
        <Typography variant="body2" textAlign="left">
          {memberInfo.description}
        </Typography>
      </Box>
    </Box>
  ) : null;
};

const WeddingParty = () => (
  <ContentPanel>
    <Box textAlign="center">
      <HockeySticksPuckImage />
      <Box mt={4} px={{ xs: 1, sm: 2, md: 6 }}>
        <Grid container spacing={{ xs: 4, md: 8, xl: 2 }}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PartyMember reverse member="veronica" />
              </Grid>
              <Grid item xs={12}>
                <PartyMember reverse member="dani" />
              </Grid>
              <Grid item xs={12}>
                <PartyMember reverse member="raven" />
              </Grid>
              <Grid item xs={12}>
                <PartyMember reverse member="stephanie" />
              </Grid>
              <Grid item xs={12}>
                <PartyMember reverse member="juliann" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PartyMember member="pat" />
              </Grid>
              <Grid item xs={12}>
                <PartyMember member="brad" />
              </Grid>
              <Grid item xs={12}>
                <PartyMember member="phil" />
              </Grid>
              <Grid item xs={12}>
                <PartyMember member="adam" />
              </Grid>
              <Grid item xs={12}>
                <PartyMember member="dennis" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box my={5}></Box>
    </Box>
  </ContentPanel>
);

export default WeddingParty;
