import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import dur from 'dayjs/plugin/duration';
import { Box, Typography } from '@mui/material';

dayjs.extend(dur);

const CEREMONY_TIME = Date.UTC(2024, 8, 6, 21, 15, 0);

const CountdownClock = () => {
  const timerRef = useRef(null);
  const [timeRemaining, setTimeRemaining] = useState(CEREMONY_TIME - Date.now());

  useEffect(() => {
    timerRef.current = setInterval(() => setTimeRemaining(CEREMONY_TIME - Date.now()), 1000);

    console.log();

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const ceremonyTimeLeft = dayjs.duration(timeRemaining);

  return (
    <Box display="flex" gap={1} justifyContent="center" my={3}>
      {ceremonyTimeLeft.asMilliseconds() > 0 ? (
        <>
          <Typography>{ceremonyTimeLeft.format(`D ${ceremonyTimeLeft.days() === 1 ? '[day]' : '[days]'}`)}</Typography>
          <Typography>{ceremonyTimeLeft.format(`H ${ceremonyTimeLeft.hours() === 1 ? '[hour]' : '[hours]'}`)}</Typography>
          <Typography>{ceremonyTimeLeft.format(`m ${ceremonyTimeLeft.minutes() === 1 ? '[minute]' : '[minutes]'}`)}</Typography>
        </>
      ) : (
        <>
          <Typography>We're Married!</Typography>
        </>
      )}
    </Box>
  );
};

export default CountdownClock;
