import { awsLink } from './components/views/Gallery/photos';

const LOT_22_HREF = 'https://www.google.com/maps/place/SDC+Parking+Lot,+Houghton,+MI+49931/@47.1116092,-88.5478043,17z';
const PARKING_IMAGE = awsLink('parking.png', 956, 448);
const BREAKERS_HREF = 'https://maps.app.goo.gl/ZG718G7FKgrt7TDd7';
const BREAKERS_IMAGE = awsLink('todo/breakers.jpg', 1008, 756);
const BROCKWAY_HREF = 'https://maps.app.goo.gl/mbFNT4BihNuK5Rsy5';
const BROCKWAY_IMAGE = awsLink('todo/brockway.jpg', 1008, 756);
const HUNGARIANFALLS_HREF = 'https://keweenawlandtrust.org/protected-lands/hungarian-falls-nature-area';
const HUNGARIANFALLS_IMAGE = awsLink('todo/hungarianfalls.jpg', 738, 480);
const NARA_HREF = 'https://www.cityofhoughton.com/nara-nature-park/';
const NARA_IMAGE = awsLink('todo/nara.jpg', 1024, 678);
const WATERFRONT_HREF = 'https://maps.app.goo.gl/3FHN7jwgABXq32yL7';
const WATERFRONT_IMAGE = awsLink('todo/waterfront.jpg', 750, 500);
const FOURSEASONS_HREF = 'https://maps.app.goo.gl/RRwPv1NSkMk1hfXH6';
const FOURSEASONS_IMAGE = awsLink('todo/fourseasons.jpg', 1024, 768);
const HARBORHAUS_HREF = 'https://maps.app.goo.gl/VbnagT2zyFCUZjqs9';
const HARBORHAUS_IMAGE = awsLink('todo/harborhaus.jpg', 700, 400);
const GLACIA_HREF = 'https://maps.app.goo.gl/kKPNnPXYmZYXGEjH6';
const GLACIA_IMAGE = awsLink('todo/glacia.jpg', 1024, 768);
const SUOMI_HREF = 'https://maps.app.goo.gl/YZiw16ycHoGiWhtZA';
const SUOMI_IMAGE = awsLink('todo/suomi.jpg', 1024, 768);
const DEN_HREF = 'https://maps.app.goo.gl/HpGVeCNckp2yTMfK9';
const DEN_IMAGE = awsLink('todo/den.jpg', 1004, 753);
const AMBO_HREF = 'https://maps.app.goo.gl/trC9PXyuojRQ5sj39';
const AMBO_IMAGE = awsLink('todo/ambo.jpg', 700, 466);
const KBC_HREF = 'https://maps.app.goo.gl/8AXKdCyx2UYHcWwo6';
const KBC_IMAGE = awsLink('todo/kbc.jpg', 1000, 750);
const BB_HREF = 'https://maps.app.goo.gl/psCFnP1mSSGwNnDt7';
const BB_IMAGE = awsLink('todo/bbbar.jpg', 1024, 683);
export const AMC_HREF = 'https://adventureminetours.com';
export const AMC_IMAGE = awsLink('todo/amc.jpg', 960, 540);
export const AES_HREF = 'https://museum.mtu.edu';
export const AES_IMAGE = awsLink('todo/aes.jpg', 960, 540);
export const CMK_HREF = 'https://carnegiekeweenaw.org';
export const CMK_IMAGE = awsLink('todo/cmk.jpg', 985, 657);
export const FWSP_HREF = 'https://www.michigan.org/property/fort-wilkins-historic-state-park';
export const FWSP_IMAGE = awsLink('todo/fwsp.jpg', 1000, 563);
export const KNHP_HREF = 'https://www.nps.gov/kewe/index.htm';
export const KNHP_IMAGE = awsLink('todo/knhp.jpg', 960, 540);
export const QM_HREF = 'https://quincymine.com';
export const QM_IMAGE = awsLink('todo/qm.jpg', 960, 540);
export const QSW_HREF = 'https://quincymine.com/smelter-tour/';
export const QSW_IMAGE = awsLink('todo/qsw.jpg', 1024, 681);

export {
  AMBO_HREF,
  AMBO_IMAGE,
  KBC_HREF,
  KBC_IMAGE,
  BB_HREF,
  BB_IMAGE,
  DEN_HREF,
  DEN_IMAGE,
  SUOMI_HREF,
  SUOMI_IMAGE,
  GLACIA_HREF,
  GLACIA_IMAGE,
  HARBORHAUS_HREF,
  HARBORHAUS_IMAGE,
  FOURSEASONS_HREF,
  FOURSEASONS_IMAGE,
  BREAKERS_HREF,
  BREAKERS_IMAGE,
  BROCKWAY_HREF,
  BROCKWAY_IMAGE,
  HUNGARIANFALLS_HREF,
  HUNGARIANFALLS_IMAGE,
  NARA_HREF,
  NARA_IMAGE,
  WATERFRONT_HREF,
  WATERFRONT_IMAGE,
  LOT_22_HREF,
  PARKING_IMAGE,
};
