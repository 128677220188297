import { createTheme } from '@mui/material';
import components from './components';

let theme = createTheme();

theme = createTheme({
  palette: {
    background: {
      default: '#fbf8ff',
    },
    text: {
      primary: '#522e55',
    },
    primary: {
      main: '#522e55',
    },
  },
  typography: {
    fontFamily: ['Open Sans', '-apple-system', 'system-ui', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['Cardo', 'serif'].join(','),
      fontSize: 40,
      lineHeight: 1.3,
      fontWeight: 400,
      textTransform: 'uppercase',
      marginTop: theme.spacing(3),
      letterSpacing: 2,
    },
    h2: {
      fontFamily: ['Cardo', 'serif'].join(','),
      fontSize: 56,
      lineHeight: 1.3,
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: 2,
    },
    h3: {
      fontFamily: ['Cardo', 'serif'].join(','),
      fontSize: 35,
      lineHeight: 1.3,
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: 2,
    },
    h4: {
      fontFamily: ['Cardo', 'serif'].join(','),
      fontSize: 24,
      lineHeight: 1.3,
      fontWeight: 400,
      letterSpacing: 2,
    },
    h5: {
      fontFamily: ['Cardo', 'serif'].join(','),
      fontSize: 18,
      lineHeight: 1.3,
      fontWeight: 400,
      letterSpacing: 2,
    },
    h6: {
      fontFamily: ['Segoe UI', 'Roboto', 'sans-serif'].join(','),
      fontSize: 18,
      lineHeight: 1.3,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    caption: {
      fontFamily: ['Segoe UI', 'Roboto', 'sans-serif'].join(','),
      fontSize: 14,
    },
  },
});

theme = createTheme(theme, {
  components: components(theme),
  overrides: {},
});

export default theme;
