import { Box, Typography } from '@mui/material';
import ContentPanel from '../../layout/ContentPanel';
import CountdownClock from './CountdownClock';
import HockeySticksPuckImage from '../../shared/HockeySticksPuckImage';

const Home = () => (
  <Box position="relative">
    <Box
      zIndex={100}
      maxHeight={800}
      overflow="hidden"
      sx={{
        '& img': {
          objectFit: 'cover',
          objectPosition: 'bottom',
        },
      }}
    >
      <img
        src="https://d1anuvz8i843i1.cloudfront.net/production/images/skates_edited.jpg?width=1900&height=800"
        alt="skates on ice with 906 puck"
        width="100%"
      />
    </Box>
    <Box position="relative" top={-6}>
      <ContentPanel>
        <Box textAlign="center">
          <HockeySticksPuckImage />
          <Box mt={5} mb={11}>
            <Typography variant="h2" fontSize={{ xs: 35, sm: 56 }}>
              Britta Anderson
            </Typography>
            <Box mb={1}>
              <Typography variant="h2">&amp;</Typography>
            </Box>
            <Typography variant="h2" fontSize={{ xs: 35, sm: 56 }}>
              Brandon Benedict
            </Typography>
          </Box>
          <Box my={5}>
            <Typography variant="h3">September 6, 2024</Typography>
            <Box my={5}>
              <Typography variant="h4">John MacInnes Student Ice Arena</Typography>
              <Typography variant="h4">&amp;</Typography>
              <Typography variant="h4">Dee Stadium</Typography>
            </Box>
            <Typography variant="h4">Houghton, MI</Typography>
            <CountdownClock />
          </Box>
        </Box>
      </ContentPanel>
    </Box>
  </Box>
);

export default Home;
