import React from 'react';

import { Box, Typography } from '@mui/material';
import HockeySticksPuckImage from '../../shared/HockeySticksPuckImage';

const Footer = () => {
  return (
    <Box textAlign="center" pb={5}>
      <Box mb={3}>
        <HockeySticksPuckImage />
      </Box>
      <Typography variant="caption">&copy; 2024&nbsp;Brandon Benedict. All rights reserved.</Typography>
    </Box>
  );
};

export default Footer;
