import { Close, Menu } from '@mui/icons-material';
import { TabList } from '@mui/lab';
import { Box, Button, Drawer, IconButton, Tab, Typography, useMediaQuery, useTheme } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';

const pages = [
  {
    id: 'cover',
    name: 'Cover',
  },
  {
    id: 'home',
    name: 'Home',
  },
  {
    id: 'our-story',
    name: 'Our Story',
  },
  {
    id: 'schedule',
    name: 'Schedule',
  },
  {
    id: 'travel',
    name: 'Travel',
  },
  {
    id: 'registry',
    name: 'Registry',
  },
  {
    id: 'wedding-party',
    name: 'Wedding Party',
  },
  {
    id: 'gallery',
    name: 'Gallery',
  },
  {
    id: 'things-to-do',
    name: 'Things To Do',
  },
  {
    id: 'faqs',
    name: 'FAQs',
  },
];

const Header = ({ currentTab, handleTabChange }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box component="header" display={{ xs: 'none', md: 'block' }} bgcolor="background.default">
        <Box px={4} py={2} display="flex" justifyContent="space-between">
          <Box display="flex" gap={4}>
            <Typography variant="body2">9.06.24</Typography>
            <Typography variant="body2">Houghton, MI</Typography>
          </Box>
          <Typography variant="body2">#bb906</Typography>
        </Box>
        <Typography variant="h1" textAlign="center">
          Brandon &amp; Britta
        </Typography>
        <Box
          component="nav"
          display="flex"
          justifyContent="center"
          mt={2}
          pb={1.5}
          borderBottom={({ palette }) => `3px solid ${palette.primary.main}`}
          boxShadow="0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.04),0px 1px 3px 0px rgba(0,0,0,0.02)"
        >
          <TabList onChange={handleTabChange}>
            {pages.map((page) => (
              <Tab label={page.name} value={page.id} key={page.id} />
            ))}
          </TabList>
        </Box>
      </Box>
      {isMobile ? (
        <>
          <Box
            bgcolor="background.default"
            display="flex"
            justifyContent="center"
            alignItems="center"
            py={1.5}
            position="fixed"
            top={0}
            left={0}
            right={0}
            zIndex={100}
            borderBottom={({ palette }) => `3px solid ${palette.primary.main}`}
            boxShadow="0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.04),0px 1px 3px 0px rgba(0,0,0,0.02)"
          >
            <Box>
              <Typography fontSize={24} letterSpacing={1.5} variant="caption">
                {currentTab.replaceAll('-', ' ').toUpperCase()}
              </Typography>
            </Box>
            <Box position="absolute" right={8} bgcolor="background.default" borderRadius="50%">
              <IconButton
                size="large"
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  mixpanel.track('Mobile menu', { newState: !menuOpen ? 'open' : 'closed' });
                }}
              >
                <Menu />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <Drawer open={menuOpen} anchor="top" onClose={() => {
              setMenuOpen(!menuOpen);
              mixpanel.track('Mobile menu', { newState: 'closed' });
            }}>
              <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                <Box textAlign="right" mt={1} mr={2}>
                  <IconButton
                    size="large"
                    onClick={() => {
                      setMenuOpen(!menuOpen);
                      mixpanel.track('Mobile menu', { newState: 'closed' });
                    }}
                  >
                    <Close />
                  </IconButton>
                </Box>
                <Box py={2} display="flex" flexDirection="column" textAlign="center">
                  {pages.map((page) => (
                    <Box key={page.id}>
                      <Button
                        onClick={(e) => {
                          handleTabChange(e, page.id);
                          setMenuOpen(false);
                          if (typeof window !== 'undefined') {
                            window.scrollTo(0, 0);
                          }
                        }}
                      >
                        {page.name}
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Drawer>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default Header;
