import { Box, styled } from '@mui/material';

const Content = styled(Box)(({ theme }) => ({
  maxWidth: 1024,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 0,
  padding: `${theme.spacing(4)} ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(6)} 0 0 0`,
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 1280,
  },
}));

const Panel = styled(Box)(({ theme }) => ({
  // position: 'relative',
}));

const ContentPanel = ({ children }) => (
  <Panel>
    <Content>{children}</Content>
  </Panel>
);

export default ContentPanel;
