import { useEffect, useState } from 'react';

import { TabContext, TabPanel } from '@mui/lab';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { Analytics } from '@vercel/analytics/react';
import { RouterProvider, createBrowserRouter, useNavigate, useParams } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';
import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import Cover from './components/views/Cover';
import Faqs from './components/views/Faqs';
import Gallery from './components/views/Gallery';
import { awsLink } from './components/views/Gallery/photos';
import Home from './components/views/Home';
import OurStory from './components/views/OurStory';
import Registry from './components/views/Registry';
import Schedule from './components/views/Schedule';
import ThingsToDo from './components/views/ThingsToDo';
import Travel from './components/views/Travel';
import WeddingParty from './components/views/WeddingParty';
import theme from './theme';

mixpanel.init('21930b935fa1e654bd14c3fbe498cd65', {
  debug: true,
  track_pageview: true,
  track_links: true,
  persistence: 'localStorage',
});

const topBgProps = (value) => ({
  xs: value === 'home' ? 50 : 0,
  sm: value === 'home' ? 225 : 0,
  md: value === 'home' ? 370 : 0,
  lg: value === 'home' ? 490 : 0,
  xl: value === 'home' ? 640 : 0,
});

const MyTabPanel = ({ children, value }) => (
  <TabPanel value={value}>
    <Box position="relative" minHeight={{ xs: 'calc(100vh - 65px)', md: 'calc(100vh - 183px)' }}>
      {/* <Box position="absolute" top={0} bottom={0} right={0} left={0} zIndex={-1}> */}
      <Box position="absolute" top={topBgProps(value)} right={0} maxWidth={{ xs: 150, md: 275 }} display="flex" zIndex={-1}>
        <img src={awsLink('ur-bg.png', 250, 614)} alt="brush strokes" width="100%" />
      </Box>
      <Box position="absolute" bottom={0} left={0} maxWidth={{ xs: 100, md: 225 }} display="flex" zIndex={-1}>
        <img src={awsLink('ll-bg.png', 250, 1132)} alt="brush strokes" width="100%" />
      </Box>
      {/* </Box> */}
      <Box zIndex={1}>{children}</Box>
    </Box>
  </TabPanel>
);

const MainContent = () => {
  const { section } = useParams();

  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(section ?? 'home');

  const handleTabChange = (_, newTab) => {
    if (newTab === 'cover') {
      mixpanel.track('Go to cover');
      navigate('/');
    } else {
      navigate(`/${newTab}`);
    }
  };

  useEffect(() => {
    if (!section) {
      setCurrentTab('home');
    } else {
      setCurrentTab(section);
    }
    mixpanel.track('Page Viewed', { tab: section });
  }, [section]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <TabContext value={currentTab}>
          <Header currentTab={currentTab} handleTabChange={handleTabChange} />
          <Box pt={{ xs: 8, md: 0 }}>
            <MyTabPanel value="home">
              <Home />
              <Footer />
            </MyTabPanel>
            <MyTabPanel value="our-story">
              <OurStory />
              <Footer />
            </MyTabPanel>
            <MyTabPanel value="schedule">
              <Schedule />
              <Footer />
            </MyTabPanel>
            <MyTabPanel value="travel">
              <Travel />
              <Footer />
            </MyTabPanel>
            <MyTabPanel value="registry">
              <Registry />
              <Footer />
            </MyTabPanel>
            <MyTabPanel value="wedding-party">
              <WeddingParty />
              <Footer />
            </MyTabPanel>
            <MyTabPanel value="gallery">
              <Gallery />
              <Footer />
            </MyTabPanel>
            <MyTabPanel value="things-to-do">
              <ThingsToDo />
              <Footer />
            </MyTabPanel>
            <MyTabPanel value="faqs">
              <Faqs />
              <Footer />
            </MyTabPanel>
            <Analytics />
          </Box>
        </TabContext>
      </CssBaseline>
    </ThemeProvider>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Cover />
        </CssBaseline>
      </ThemeProvider>
    ),
  },
  {
    path: '/:section',
    element: <MainContent />,
  },
]);

const App = () => <RouterProvider router={router} />;

export default App;
