import { ExpandMore, InstallMobile, QuestionMark } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography, styled } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useState } from 'react';
import { LOT_22_HREF } from '../../../constants';
import linkTrack from '../../../utils/linkTrack';
import ContentPanel from '../../layout/ContentPanel';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '& .MuiAccordionSummary-root:hover': {
    background: theme.palette.grey[100],
  },
}));

const MyAccordion = ({ children, itemKey }) => (
  <StyledAccordion elevation={0} onChange={(_, expanded) => mixpanel.track('FAQ Accordion', { faq: itemKey, expanded })}>
    {children}
  </StyledAccordion>
);

const Faqs = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const loadPrompt = useCallback(() => {
    if (!deferredPrompt && typeof window !== 'undefined') {
      window.addEventListener('beforeinstallprompt', (e) => {
        setDeferredPrompt(e);
      });
    }
  }, [deferredPrompt]);

  useEffect(() => {
    loadPrompt();
  });

  return (
    <ContentPanel>
      <Box textAlign="center" mb={4}>
        <QuestionMark color="primary" fontSize="large" />
      </Box>
      <Box mb={4} maxWidth={750} mx="auto">
        <MyAccordion itemKey="photos">
          <AccordionSummary expandIcon={<ExpandMore />}>Can we take photos during the ceremony?</AccordionSummary>
          <AccordionDetails>
            <Typography paragraph variant="body2" pt={1}>
              We would prefer it if you don't take photos with your phone or personal cameras. We are more than happy to
              share our professional photos with you! Also, with the ceremony being on the ice and our guests sitting in the
              stands, there will be more distance between the guests and the ceremony.
            </Typography>
          </AccordionDetails>
        </MyAccordion>
        <MyAccordion itemKey="ice-arena">
          <AccordionSummary expandIcon={<ExpandMore />}>Where is the John MacInnes Student Ice Arena?</AccordionSummary>
          <AccordionDetails>
            <Typography paragraph variant="body2" pt={1}>
              The John MacInnes Student Ice Arena is in the Student Development Complex (SDC) on Michigan Tech's campus.
              There will be signs to direct you where to go in the SDC.{' '}
              <a
                href={LOT_22_HREF}
                target="_blank"
                rel="noreferrer"
                onClick={() => linkTrack(LOT_22_HREF, { link: 'lot 22 maps' })}
              >
                Lot 22
              </a>{' '}
              is open visitor parking at the SDC and closest to the main entrance.
            </Typography>
          </AccordionDetails>
        </MyAccordion>
        <MyAccordion itemKey="colors">
          <AccordionSummary expandIcon={<ExpandMore />}>What is the dress code?</AccordionSummary>
          <AccordionDetails>
            <Typography paragraph variant="body2" pt={1}>
              This event is cocktail attire. Break out that suit and tie, slacks and blazer/sport coat, or tea / knee / full
              length dress and look your best!
            </Typography>
          </AccordionDetails>
        </MyAccordion>
        <MyAccordion itemKey="pets">
          <AccordionSummary expandIcon={<ExpandMore />}>Are pets allowed?</AccordionSummary>
          <AccordionDetails>
            <Typography paragraph variant="body2" pt={1}>
              Only registered service animals are allowed at the ceremony and reception.
            </Typography>
          </AccordionDetails>
        </MyAccordion>
        <MyAccordion itemKey="children">
          <AccordionSummary expandIcon={<ExpandMore />}>Are children allowed?</AccordionSummary>
          <AccordionDetails>
            <Typography paragraph variant="body2" pt={1}>
              Yes, children are allowed. However, there will not be activities for children so please plan accordingly.
            </Typography>
          </AccordionDetails>
        </MyAccordion>
        <MyAccordion itemKey="safety">
          <AccordionSummary expandIcon={<ExpandMore />}>Why is safety #6?</AccordionSummary>
          <AccordionDetails>
            <Typography paragraph variant="body2" pt={1}>
              It's a pep band thing. But safety <em>is</em> important, which is why our two hotel blocks are both within
              walking distance of our reception.
            </Typography>
          </AccordionDetails>
        </MyAccordion>
        <MyAccordion itemKey="houghton">
          <AccordionSummary expandIcon={<ExpandMore />}>Where IS Houghton?</AccordionSummary>
          <AccordionDetails>
            <Typography paragraph variant="body2" pt={1}>
              Houghton is located in the Upper Peninsula of Michigan, specifically in the Keweenaw (key-wuh-naa) Peninsula.
              It's approximately an eight hour drive from Lansing, MI; a seven hour drive from Chicago, IL; or a six hour
              drive from Minneapolis, MN. Houghton is also in the Eastern time zone, even though Houghton is further west
              than Green Bay, Milwaukee, and Chicago.
            </Typography>
          </AccordionDetails>
        </MyAccordion>
        <MyAccordion itemKey="colors">
          <AccordionSummary expandIcon={<ExpandMore />}>What are the wedding colors?</AccordionSummary>
          <AccordionDetails>
            <Typography paragraph variant="body2" pt={1}>
              The wedding colors are a dark purple and medium purple, with pops of darker red and silver accents.
            </Typography>
          </AccordionDetails>
        </MyAccordion>
        <MyAccordion itemKey="website">
          <AccordionSummary expandIcon={<ExpandMore />} onClick={loadPrompt}>Who made your wedding website?</AccordionSummary>
          <AccordionDetails>
            <Typography paragraph variant="body2" pt={1}>
              Brandon did all the work in his spare time as sites like Zola and The Knot were frustrating him as a full time
              web developer. Please excuse him if there's any issues or feel free to reach out if you have any issues.
            </Typography>
            {deferredPrompt ? (
              <>
                <Typography paragraph variant="body2" pt={1}>
                  Additionally, you can install this website as an "app" on Android or iOS with the below button to quickly
                  reference any info you may need.
                </Typography>
                <Box py={1}>
                  <Button
                    variant="contained"
                    id="installApp"
                    onClick={async () => {
                      mixpanel.track('App install prompted');
                      deferredPrompt.prompt();
                      const { outcome } = await deferredPrompt.userChoice;
                      if (outcome === 'accepted') {
                        mixpanel.track('App installed');
                        setDeferredPrompt(null);
                      } else {
                        mixpanel.track('App prompt denied');
                      }
                    }}
                    startIcon={<InstallMobile />}
                  >
                    Install as app
                  </Button>
                </Box>
              </>
            ) : null}
          </AccordionDetails>
        </MyAccordion>
      </Box>
    </ContentPanel>
  );
};

export default Faqs;
