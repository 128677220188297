import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { oneOf } from 'prop-types';

import ContentPanel from '../../layout/ContentPanel';
import { awsPhotos, manualPhoto } from '../Gallery/photos';

const timelineSxProps = {
  '&::before': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '50%',
    borderLeft: '1px dashed black',
    content: 'attr(data-content)',
    transform: 'translate(-50%, 0%)',
    height: 'calc(50% - 0.6rem)',
  },
  '&::after': {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    borderLeft: '1px dashed black',
    content: 'attr(data-content)',
    transform: 'translate(-50%, 0%)',
    height: 'calc(50% - 0.6rem)',
  },
  '&>.dot': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '50%',
    width: 5,
    height: 5,
    background: 'black',
    paddingTop: 0,
    paddingLeft: 0,
    transform: 'translate(-50%, -50%)',
  },
};

const TimelineEntry = ({ imagePlacement, text, photo }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const leftImageAlign = imagePlacement === 'left';
  let flexDirection = isMobile ? 'column-reverse' : leftImageAlign ? 'row-reverse' : 'row';
  return (
    <Grid
      mt={0.25}
      container
      flexDirection={flexDirection}
      sx={isMobile ? {} : timelineSxProps}
      position="relative"
      data-content=""
      alignItems="center"
    >
      {isMobile ? null : <Box className="dot" />}
      <Grid
        item
        xs={12}
        sm={6}
        py={2}
        {...(isMobile ? { textAlign: 'center', px: 2 } : leftImageAlign ? { pl: 2 } : { pr: 2 })}
      >
        {text}
      </Grid>
      <Grid
        item
        xs={10}
        sm={6}
        py={2}
        display="flex"
        {...(isMobile
          ? { justifyContent: 'center' }
          : leftImageAlign
            ? { justifyContent: 'right', pr: 2 }
            : { justifyContent: 'left', pl: 2 })}
      >
        <Box
          maxWidth={{ xs: '95%', sm: '75%' }}
          maxHeight={{ xs: 'none', sm: '25vh' }}
          sx={{ aspectRatio: photo.width / photo.height, '& > img': { borderRadius: 2 } }}
        >
          <img src={photo.src} width="100%" height="100%" alt={photo.text} />
        </Box>
      </Grid>
    </Grid>
  );
};

TimelineEntry.propTypes = {
  imagePlacement: oneOf(['left', 'right']).isRequired,
};

const Timeline1 = () => (
  <Typography paragraph>
    We met in Marquette for the 2018 WCHA championship game, Tech versus Northern. This is our first ever photo together and
    Brandon tried to slink out of it but Britta coaxed him into it! Brad and Gina Nedrow (the two people on the right) were
    mutual friends of ours but somehow we had never crossed paths before this. We can thank Brad and Gina Nedrow, Amanda
    Manning, and Luke Pretzl for all helping us to cross paths.
  </Typography>
);

const Timeline2 = () => (
  <Typography paragraph>
    We met again after the game to celebrate our win over our rival! Although we didn't talk much that night, it wasn't long
    until Brandon reached out to Britta to start a conversation.
  </Typography>
);

const Timeline3 = () => (
  <Typography paragraph>
    Our first date was skating at Wings West in Kalamazoo. It seemed only fitting that we met because of hockey that our
    first date had to be skating! A week later, we officially started dating.
  </Typography>
);

const Timeline4 = () => (
  <Typography paragraph>
    Britta joined the Michigan Tech Alumni Board of Directors in July 2018, with their first meeting being at Alumni Reunion
    2018. It was the first time back to Michigan Tech for Brandon since his graduation in 2012 but Britta had been back
    multiple times a year since graduation in 2015. This was our first bigger trip together, only a few weeks after spending
    a week with Brandon's family at their cabin in the middle of the UP.
  </Typography>
);

const Timeline5 = () => (
  <Typography paragraph>
    In August 2018, we each adopted two kitties from the same litter. Brandon and Britta moved in together four months after,
    so the fur babies weren't apart for too long! Three of our kitties have hockey related names: MacInnes, Gordie, and Izzy.
    Callie was such a cute calico that nothing else suited her.
  </Typography>
);

const Timeline6 = () => (
  <Typography paragraph>
    We continued to travel around the country for Tech hockey: going out to Clarkson in 2018, GLI at LCA in 2018 and 2019,
    GLI at Van Andel in 2022 and 2023, Bowling Green State University, Robert Morris and Lake Superior State in 2019, Arizona
    State and Alabama Huntsville in 2020, NCAA tournament in 2022 in Colorado, University of Minnesota Duluth in 2023, and
    too many trips to Ferris State University to count.
  </Typography>
);

const Timeline7 = () => (
  <Typography paragraph>
    Britta's brother got married in June 2019. He had wanted to ask Brandon to be a groomsman but we'd only been dating six
    months at the time. Britta had said that she didn't think there was any reason to worry, as they knew Brandon was a
    keeper. Before Britta and Brandon met, Eric and Brandon were in the same section (trombone) in the Huskies Pep Band and
    played broomball together. Brandon wasn't only part of the wedding because he was dating Britta!
  </Typography>
);

const Timeline8 = () => (
  <Typography paragraph>
    After a spontaneous look at houses in the area in September 2020, we found some land that called to us both. In October
    2020, we officially became landowners in Houghton, starting the process to move back to the UP!
  </Typography>
);

const Timeline9 = () => (
  <Typography paragraph>
    Brandon proposed on the ice at the John MacInnes Student Ice Arena during Winter Carnival 2022. With the help of a few
    members of the Alumni Board, other friends, and hockey legend John Scott, Brandon kept it a complete secret from Britta
    and she was completely shocked!
  </Typography>
);

const Timeline10 = () => (
  <Typography paragraph>
    A few years in the making, we officially moved back to the UP in June 2023 to start our next chapter. We had established
    early on in our relationship that it was a dream for both of us to move back to the UP, to make our lives in a place we
    love.
  </Typography>
);

const Timeline11 = () => (
  <Typography paragraph>
    And now we can’t wait to celebrate our marriage with our loved ones in the place that has been home in both of our hearts
    for decades!
  </Typography>
);

const OurStory = () => (
  <ContentPanel>
    <Box mb={8} textAlign="center" maxWidth={600} mx="auto">
      <Typography>
        Our story is in some regards, traditional, and in others far from it. One common thread, which continues to this day
        and undoubtedly will continue for years to come, is hockey. Read on to find out just why Britta&lsquo;s ring has
        hockey sticks on it!
      </Typography>
    </Box>
    <Box mb={5}>
      <TimelineEntry imagePlacement="left" text={<Timeline1 />} photo={manualPhoto(350, awsPhotos[0])} />
      <TimelineEntry imagePlacement="right" text={<Timeline2 />} photo={manualPhoto(150, awsPhotos[1])} />
      <TimelineEntry imagePlacement="left" text={<Timeline3 />} photo={manualPhoto(200, awsPhotos[2])} />
      <TimelineEntry imagePlacement="right" text={<Timeline4 />} photo={manualPhoto(275, awsPhotos[4])} />
      <TimelineEntry imagePlacement="left" text={<Timeline5 />} photo={manualPhoto(375, awsPhotos[59])} />
      <TimelineEntry imagePlacement="right" text={<Timeline6 />} photo={manualPhoto(375, awsPhotos[60])} />
      <TimelineEntry imagePlacement="left" text={<Timeline7 />} photo={manualPhoto(200, awsPhotos[9])} />
      <TimelineEntry imagePlacement="right" text={<Timeline8 />} photo={manualPhoto(375, awsPhotos[17])} />
      <TimelineEntry imagePlacement="left" text={<Timeline9 />} photo={manualPhoto(200, awsPhotos[32])} />
      <TimelineEntry imagePlacement="right" text={<Timeline10 />} photo={manualPhoto(375, awsPhotos[18])} />
      <TimelineEntry imagePlacement="left" text={<Timeline11 />} photo={manualPhoto(175, awsPhotos[46])} />
    </Box>
  </ContentPanel>
);

export default OurStory;
