import {
  Directions,
  ExpandMore,
  Forest,
  History,
  LocalActivity,
  LocalBar,
  LocalDining,
  OpenInNew,
} from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography, styled } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import {
  AES_HREF,
  AES_IMAGE,
  AMBO_HREF,
  AMBO_IMAGE,
  AMC_HREF,
  AMC_IMAGE,
  BB_HREF,
  BB_IMAGE,
  BREAKERS_HREF,
  BREAKERS_IMAGE,
  BROCKWAY_HREF,
  BROCKWAY_IMAGE,
  CMK_HREF,
  CMK_IMAGE,
  DEN_HREF,
  DEN_IMAGE,
  FOURSEASONS_HREF,
  FOURSEASONS_IMAGE,
  FWSP_HREF,
  FWSP_IMAGE,
  GLACIA_HREF,
  GLACIA_IMAGE,
  HARBORHAUS_HREF,
  HARBORHAUS_IMAGE,
  HUNGARIANFALLS_HREF,
  HUNGARIANFALLS_IMAGE,
  KBC_HREF,
  KBC_IMAGE,
  KNHP_HREF,
  KNHP_IMAGE,
  NARA_HREF,
  NARA_IMAGE,
  QM_HREF,
  QM_IMAGE,
  QSW_HREF,
  QSW_IMAGE,
  SUOMI_HREF,
  SUOMI_IMAGE,
  WATERFRONT_HREF,
  WATERFRONT_IMAGE,
} from '../../../constants';
import linkTrack from '../../../utils/linkTrack';
import ContentPanel from '../../layout/ContentPanel';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '& .MuiAccordionSummary-root:hover': {
    background: theme.palette.grey[100],
  },
}));

const MyAccordion = ({ children, itemKey }) => (
  <StyledAccordion elevation={0} onChange={(_, expanded) => mixpanel.track('Todo Accordion', { faq: itemKey, expanded })}>
    {children}
  </StyledAccordion>
);

const ToDoItem = ({ image, href, buttonIcon, buttonTitle, description }) => (
  <Box mb={4}>
    <Box textAlign="center" mb={2}>
      <Button
        variant="outlined"
        endIcon={buttonIcon}
        href={href}
        target="_blank"
        onClick={() => linkTrack(href, { link: buttonTitle })}
      >
        {buttonTitle}
      </Button>
    </Box>
    <Box display="flex" gap={2} width="100%" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center">
      <Typography paragraph variant="body2" pl={2} pt={1}>
        {description}
      </Typography>
      <Box
        borderRadius={2}
        overflow="hidden"
        display="flex"
        minWidth={{ xs: '80%', sm: '40%' }}
        maxWidth={{ xs: '80%', sm: '40%' }}
      >
        <img src={image} alt={buttonTitle} width="100%" />
      </Box>
    </Box>
  </Box>
);

ToDoItem.defaultProps = {
  buttonIcon: <Directions />,
};

const ThingsToDo = () => (
  <ContentPanel>
    <Box textAlign="center" mb={4}>
      <LocalActivity color="primary" sx={{ fontSize: 60 }} />
    </Box>
    <Box mb={4} mx="auto" maxWidth={800}>
      <Typography paragraph>
        Houghton and the Keweenaw is a beautiful place with great food, phenomenal views, and absolutely stunning nature.
      </Typography>
      <MyAccordion itemKey="outdoors">
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Forest />
          <Typography pl={1}>Outdoors / Nature</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box pt={1} />
          <ToDoItem
            image={BREAKERS_IMAGE}
            href={BREAKERS_HREF}
            buttonTitle="Breakers Beach"
            description="Black stamp sand beach, perfect location to enjoy a sunset. Follow directions to North Canal Township Park, keep going past the pavilion, parking available near beach."
          />
          <ToDoItem
            image={BROCKWAY_IMAGE}
            href={BROCKWAY_HREF}
            buttonTitle="Brockway Mountain"
            description="Located just outside Copper Harbor, head to the top for breathtaking views of Lake Superior and thousands of acres of the Keweenaw."
          />
          <ToDoItem
            image={HUNGARIANFALLS_IMAGE}
            href={HUNGARIANFALLS_HREF}
            buttonTitle="Hungarian Falls"
            buttonIcon={<OpenInNew />}
            description="Series of waterfalls accessible by varying lengths of hiking trails, shortest trail about 400 yards, longest about 2 miles"
          />
          <ToDoItem
            image={NARA_IMAGE}
            href={NARA_HREF}
            buttonTitle="Nara Nature Park"
            buttonIcon={<OpenInNew />}
            description="100 acre nature park, two miles east of Michigan Tech's campus. Over 50 miles of trails for hiking, running, and biking."
          />
          <ToDoItem
            image={WATERFRONT_IMAGE}
            href={WATERFRONT_HREF}
            buttonTitle="Houghton Waterfront Trail"
            description="2 and a half mile paved trail along the Portage Canal, Chutes and Ladders (Kestner Park) on the west end, and Prince's Point on the east end, great views of the Lift Bridge, right on the edge of downtown Houghton."
          />
        </AccordionDetails>
      </MyAccordion>
      <MyAccordion itemKey="food">
        <AccordionSummary expandIcon={<ExpandMore />}>
          <LocalDining />
          <Typography pl={1}>Food</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography paragraph mb={2}>
            Just some of our favorites below.{' '}
            <em>Reservations are highly recommended for Four Seasons Tea Room, Harbour Haus, and Glacia Tavern.</em>
          </Typography>
          <ToDoItem
            image={AMBO_IMAGE}
            href={AMBO_HREF}
            buttonTitle="Ambassador Restaurant"
            description="Known to locals just as the 'Ambo', located in downtown Houghton, known for its thin crust pizzas and 25 oz fishbowl cocktails. Brandon's favorite is the Long Alaskan, and Britta's is the Sweet Tart."
          />
          <ToDoItem
            image={FOURSEASONS_IMAGE}
            href={FOURSEASONS_HREF}
            buttonTitle="Four Seasons Tea Room"
            description="Britta's arguably favorite place in Houghton, stop by for tea and treats! All tea served at the tea room can be brought home as well as the cakes; Britta's favorite is the white pear cake. The take home cakes require a minimum 24 hour notice."
          />
          <ToDoItem
            image={GLACIA_IMAGE}
            href={GLACIA_HREF}
            buttonTitle="Glacia Tavern"
            description="Located in the unincorporated community of Mohawk, this unassuming restaurant is a favorite of both of ours. Great specials and a varied menu, something for everyone!"
          />
          <ToDoItem
            image={HARBORHAUS_IMAGE}
            href={HARBORHAUS_HREF}
            buttonTitle="Harbor Haus"
            description="Fine dining on the tip of the Keweenaw, located in Copper Harbor."
          />
          <ToDoItem
            image={SUOMI_IMAGE}
            href={SUOMI_HREF}
            buttonTitle="Suomi"
            description={
              <>
                Location in downtown Houghton, Suomi Bakery and Restaurant features traditional finnish breakfasts and
                pastries. Order two pancakes only if you're REALLY hungry, they're bigger than the plate they come on.
                Pronounced <em>Sue-me</em>.
              </>
            }
          />
          <ToDoItem
            image={DEN_IMAGE}
            href={DEN_HREF}
            buttonTitle="The Den"
            description={
              <>
                Location in downtown Houghton, The Den focuses on local ingredients, closest thing to a farm to table
                restaurant in the area. Great drinks and food!
              </>
            }
          />
        </AccordionDetails>
      </MyAccordion>
      <MyAccordion itemKey="drinks">
        <AccordionSummary expandIcon={<ExpandMore />}>
          <LocalBar />
          <Typography pl={1}>Drinks</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box pt={1} />
          <ToDoItem
            image={KBC_IMAGE}
            href={KBC_HREF}
            buttonTitle="Keweenaw Brewing Company"
            description="Our local brewery where a pint of craft beer is only $4.25! Even better, you can bring in food and board games! Views of the bridge and canal from the large deck, and a great selection of growlers and merchandise as well."
          />
          <ToDoItem
            image={BB_IMAGE}
            href={BB_HREF}
            buttonTitle="B & B Bar"
            description={
              <>
                There are many bars but our favorite is the B&B, where you can get your “dinner” of Hamm and Eggs (Hamms beer
                and a pickled egg). Just remember your cash as the B&B is <em>CASH ONLY</em>, an ATM is available inside if
                you forget.
              </>
            }
          />
        </AccordionDetails>
      </MyAccordion>
      <MyAccordion itemKey="history-buffs">
        <AccordionSummary expandIcon={<ExpandMore />}>
          <History />
          <Typography pl={1}>History Buffs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography paragrpah mb={2}>
            The Copper Country offers a lot of things to do for history buffs as well! Over 7,000 years ago, native peoples
            made copper into tools and trade items. In the late 1800s, a copper boom led to mines being opened nearly
            everywhere imaginable.
          </Typography>
          <ToDoItem
            image={AMC_IMAGE}
            href={AMC_HREF}
            buttonIcon={<OpenInNew />}
            buttonTitle="Adventure Mining Company"
            description="Located about 40 miles SW of Houghton in Greenland, MI, tours are available of a copper mine where you'll experience the mine as the miners would have over 160 years ago. No lighting in the mine except for the light on your hard hat. Easily the best mine tour/experience in the Copper Country. Brandon worked as a tour guide here in the summer of 2009."
          />
          <ToDoItem
            image={AES_IMAGE}
            href={AES_HREF}
            buttonIcon={<OpenInNew />}
            buttonTitle="A.E. Seaman Mineral Museum"
            description="Located on Michigan Tech's campus, this museum is the largest public exhibit of minerals from the Great Lakes region."
          />
          <ToDoItem
            image={CMK_IMAGE}
            href={CMK_HREF}
            buttonIcon={<OpenInNew />}
            buttonTitle="Carnegie Museum of the Keweenaw"
            description={
              <>
                Located in downtown Houghton, this free museum connects people to the natural and cultural history of the
                Keweenaw Peninsula, and to science and technology. The{' '}
                <a
                  href={`${CMK_HREF}/explore`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => linkTrack(`${CMK_HREF}/explore`, { link: 'cmk explore' })}
                >
                  explore
                </a>{' '}
                page also outlines the history of many of the historic buildings in Houghton!
              </>
            }
          />
          <ToDoItem
            image={FWSP_IMAGE}
            href={FWSP_HREF}
            buttonIcon={<OpenInNew />}
            buttonTitle="Fort Wilkins State Park"
            description="Located just past Copper Harbor, on the northern tip of the Keweenaw, features a historical fort with a living history program, and two 1860s lighthouses on Lake Superior."
          />
          <ToDoItem
            image={KNHP_IMAGE}
            href={KNHP_HREF}
            buttonIcon={<OpenInNew />}
            buttonTitle="Keweenaw National Historical Park"
            description="Headquartered out of the Calumet Vistor Center (includes three floors of interactive exhibits) in Calumet, MI. The KNHP encompasses many sites in the area, including all of those listed here."
          />
          <ToDoItem
            image={QM_IMAGE}
            href={QM_HREF}
            buttonIcon={<OpenInNew />}
            buttonTitle="Quincy Mine"
            description="Located on US 41, top of the hill just northeast of Hancock, MI, the Quincy Mine boasts a mine shaft of nearly 2 miles deep along its 55 degree decline. Mine and surface tours available. "
          />
          <ToDoItem
            image={QSW_IMAGE}
            href={QSW_HREF}
            buttonIcon={<OpenInNew />}
            buttonTitle="Quincy Smelting Works"
            description="Located on the Portage Canal, the historic Quincy Smelter served many of the local area mines to produce copper ingots instead of shipping raw ore. It was closed in 1971, in recent years, many efforts have been made to preserve this historic site, and now interior tours are available to the public."
          />
        </AccordionDetails>
      </MyAccordion>
    </Box>
  </ContentPanel>
);

export default ThingsToDo;
