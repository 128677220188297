import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import PhotoAlbum from 'react-photo-album';

import { photos } from './photos';
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Captions from 'yet-another-react-lightbox/plugins/captions';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/plugins/captions.css';

const Gallery = () => {
  const [index, setIndex] = useState(-1);
  const captionsRef = useRef(null);

  return (
    <Box pt={{ xs: 4, md: 6 }}>
      <Box px={{ xs: 2, sm: 5, md: 20, xl: 40 }}>
        <PhotoAlbum
          photos={photos}
          layout="masonry"
          targetRowHeight={200}
          onClick={({ index: newIndex }) => setIndex(newIndex)}
          renderPhoto={({ photo, renderDefaultPhoto }) => (
            <Box borderRadius={2} overflow="hidden" my={1}>
              {renderDefaultPhoto({ wrapped: true })}
            </Box>
          )}
        />
      </Box>

      <Lightbox
        captions={{ ref: captionsRef }}
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails]}
        on={{
          click: () => {
            if (captionsRef.current?.visible) {
              captionsRef.current?.hide();
            }
            if (!captionsRef.current?.visible) {
              captionsRef.current?.show();
            }
          },
        }}
      />
    </Box>
  );
};

export default Gallery;
