const components = (theme) => ({
  MuiIconButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(105, 63, 108, 0.15)',
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: theme.spacing(5),
        '& .MuiTabs-indicator': {
          height: 3,
          transition: 'left width 0.3s',
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: 0,
        minHeight: theme.spacing(5),
        margin: `0 ${theme.spacing(1)}`,
        padding: `0 ${theme.spacing(0.25)}`,
        color: theme.palette.text.primary,
        borderBottom: '2px solid transparent',
        transition: 'border-color 0.2s',
        [theme.breakpoints.up('lg')]: {
          margin: `0 ${theme.spacing(3)}`,
        },
        '&.Mui-selected, &:hover': {
          color: '#2a092d',
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
      },
    },
  },
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
});

export default components;
