const breakpoints = [2400, 1080, 640, 384, 256];

const photoUrl =
  process.env.NODE_ENV === 'development' ? './hosted-images' : 'https://d1anuvz8i843i1.cloudfront.net/production/images';

const awsLink = (name, width, height) => `${photoUrl}/${name}?width=${width}&height=${height}`;

const awsPhotos = [
  {
    name: 'our-story-timeline/2018_03_1.jpg',
    width: 960,
    height: 720,
    title: 'Our First Photo',
    description: 'Marquette, MI - March 2018 - Pregame WCHA Championship',
  },
  {
    name: 'our-story-timeline/2018_03_2.jpg',
    width: 1152,
    height: 2048,
    title: 'Champions After Party',
    description: 'Marquette, MI - March 2018 - Ore Dock Brewing Co.',
  },
  {
    name: 'our-story-timeline/2018_04_1.jpg',
    width: 720,
    height: 960,
    title: 'First Date',
    description: 'Kalamazoo, MI - April 2018 - Skating at Wings West Ice Arena',
  },
  {
    name: 'our-story-timeline/2018_07_1.jpg',
    width: 960,
    height: 960,
    title: 'Leny Cabin',
    description: 'Thunder Lake - July 2018',
  },
  {
    name: 'our-story-timeline/2018_08_1.jpg',
    width: 1080,
    height: 1080,
    title: 'Alumni Reunion',
    description: 'Houghton, MI - August 2018',
  },
  {
    name: 'our-story-timeline/2018_10_1.jpg',
    width: 1860,
    height: 1508,
    title: 'Clarkson / MTU Hockey',
    description: 'Potsdam, NY - November 2018',
  },
  {
    name: 'our-story-timeline/2019_02_1.jpg',
    width: 960,
    height: 720,
    title: 'Michigan Tech Night - Red Wings',
    description: 'Detroit, MI - February 2019',
  },
  {
    name: 'our-story-timeline/2019_03_1.jpg',
    width: 1440,
    height: 810,
    title: 'BGSU / MTU Hockey',
    description: 'Bowling Green, OH - February 2019',
  },
  {
    name: 'our-story-timeline/2019_05_1.jpg',
    width: 1080,
    height: 1440,
    title: '1 Year Anniversary',
    description: 'Kalamazoo, MI - May 2019',
  },
  {
    name: 'our-story-timeline/2019_06_1.jpg',
    width: 1072,
    height: 1440,
    title: 'Eric / Audrey Anderson Wedding',
    description: 'Lansing, MI - June 2019',
  },
  {
    name: 'our-story-timeline/2019_06_2.jpg',
    width: 528,
    height: 960,
    title: 'Juliann / Toby Raikes Wedding',
    description: 'Cincinatti, OH - June 2019',
  },
  {
    name: 'our-story-timeline/2019_10_1.jpg',
    width: 1541,
    height: 2015,
    title: 'Robert Morris / MTU Hockey',
    description: 'Pittsburgh, PA - October 2019',
  },
  {
    name: 'our-story-timeline/2019_12_1.jpg',
    width: 1440,
    height: 1072,
    title: 'Skate Date',
    description: 'Houghton, MI - December 2019',
  },
  {
    name: 'our-story-timeline/2019_12_2.jpg',
    width: 720,
    height: 960,
    title: 'GLI',
    description: 'Detroit, MI - December 2019',
  },
  {
    name: 'our-story-timeline/2019_12_3.jpg',
    width: 1152,
    height: 2048,
    title: 'NYE',
    description: 'Detroit, MI - December 2019',
  },
  {
    name: 'our-story-timeline/2020_02_1.jpg',
    width: 960,
    height: 540,
    title: 'UAH / MTU Hockey',
    description: 'Huntsville, AL - January 2020',
  },
  {
    name: 'our-story-timeline/2020_02_2.jpg',
    width: 720,
    height: 960,
    title: 'FSU / MTU Hockey',
    description: 'Big Rapids, MI - February 2020',
  },
  {
    name: 'our-story-timeline/2020_10_1.jpg',
    width: 1842,
    height: 1248,
    title: 'New Copper Country Land Owners',
    description: 'Houghton, MI - October 2020',
  },
  {
    name: 'our-story-timeline/2023_06_1.jpg',
    width: 1512,
    height: 1118,
    title: 'New Home Owners',
    description: 'Houghton, MI - June 2023',
  },
  {
    name: 'hockey-trips/1-tech-wcha-championship-mar-2018.jpg',
    width: 1080,
    height: 720,
    title: '2018 WCHA Champions',
    description: 'Marquette, MI - March 2018 - This game was the reason we met, both of us are in the stands in this photo',
  },
  {
    name: 'hockey-trips/2-bgsu-feb-2019.jpg',
    width: 1440,
    height: 810,
    gallerySkip: true,
    title: 'BGSU / MTU Hockey',
    description: 'Bowling Green, OH - February 2019',
  },
  {
    name: 'hockey-trips/3-redwings-feb-2019.jpg',
    width: 960,
    height: 720,
    gallerySkip: true,
    title: 'Michigan Tech Night - Red Wings',
    description: 'Detroit, MI - February 2019',
  },
  {
    name: 'hockey-trips/4-rmu-pittsburgh-oct-2019.jpg',
    width: 1541,
    height: 2015,
    gallerySkip: true,
    title: 'Robert Morris / MTU Hockey',
    description: 'Pittsburgh, PA - October 2019',
  },
  {
    name: 'hockey-trips/5-kwings-love-your-melon-nov-2019.jpg',
    width: 960,
    height: 528,
    title: 'Hockey Fights Cancer Night - KWings',
    description: 'Kalamazoo, MI - November 2019',
  },
  {
    name: 'hockey-trips/6-tech-stuffed-toss-dec-2019.jpg',
    width: 1530,
    height: 2032,
    title: 'Clarkson / MTU "Teddy Bear" Toss Night',
    description: 'Houghton, MI - December 2019',
  },
  {
    name: 'hockey-trips/7-gli-2019.jpg',
    width: 720,
    height: 960,
    gallerySkip: true,
    title: 'GLI',
    description: 'Detroit, MI - December 2019',
  },
  {
    name: 'hockey-trips/8-gli-2019-john-scott.jpg',
    width: 1890,
    height: 1418,
    title: 'GLI - John Scott',
    description:
      'Detroit, MI - December 2019 - Little did we know he would be involved in our engagement a little over 2 years after this photo',
  },
  {
    name: 'hockey-trips/9-asu-phoenix-jan-2020.jpg',
    width: 2016,
    height: 1134,
    title: 'ASU / MTU Hockey',
    description: 'Phoenix, AZ - January 2020',
  },
  {
    name: 'hockey-trips/12-asu-phoenix-jan-2020.jpg',
    width: 2016,
    height: 1512,
    gallerySkip: false,
    title: 'ASU / MTU Hockey',
    description: 'Phoenix, AX - January 2020',
  },
  {
    name: 'hockey-trips/10-uah-huntsville-jan-2020.jpg',
    width: 960,
    height: 540,
    gallerySkip: true,
    title: 'UAH / MTU Hockey',
    description: 'Huntsville, AL - January 2020',
  },
  {
    name: 'hockey-trips/11-fsu-feb-2020.jpg',
    width: 720,
    height: 960,
    gallerySkip: true,
    title: 'FSU / MTU Hockey',
    description: 'Big Rapids, MI - February 2020',
  },
  {
    name: 'proposal-1.jpg',
    width: 2351,
    height: 1567,
    title: 'On Ice Challenge Winner - Pre Proposal',
    description: 'Houghton, MI - Winter Carnival 2022',
  },
  {
    name: 'proposal-2.jpg',
    width: 951,
    height: 1262,
    title: 'Proposal',
    description: 'Houghton, MI - Winter Carnival 2022',
  },
  {
    name: 'proposal-3.jpg',
    width: 2351,
    height: 1567,
    title: 'Post Proposal - John Scott',
    description: 'Houghton, MI - Winter Carnival 2022',
  },
  { name: 'ring-sticks.jpg', width: 1897, height: 1906, title: 'Ring Closeup', description: '' },
  {
    name: 'engagement-out-1.jpg',
    width: 2000,
    height: 3000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-out-2.jpg',
    width: 3000,
    height: 2000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-out-3.jpg',
    width: 3000,
    height: 2000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-out-4.jpg',
    width: 2000,
    height: 3000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-out-5.jpg',
    width: 2000,
    height: 3000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-out-6.jpg',
    width: 3000,
    height: 2000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-out-7.jpg',
    width: 2000,
    height: 3000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-out-8.jpg',
    width: 2000,
    height: 3000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-out-9.jpg',
    width: 3000,
    height: 2000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-1.jpg',
    width: 2545,
    height: 3000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-2.jpg',
    width: 2626,
    height: 3000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-3.jpg',
    width: 2000,
    height: 3000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-4.jpg',
    width: 2000,
    height: 3000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-5.jpg',
    width: 3000,
    height: 2000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-6.jpg',
    width: 2000,
    height: 3000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-7.jpg',
    width: 2000,
    height: 3000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-8.jpg',
    width: 3000,
    height: 2394,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-9.jpg',
    width: 3000,
    height: 2000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-10.jpg',
    width: 2000,
    height: 3000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-11.jpg',
    width: 3000,
    height: 2000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-12.jpg',
    width: 3000,
    height: 2000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-13.jpg',
    width: 3000,
    height: 2000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-14.jpg',
    width: 3000,
    height: 2000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  {
    name: 'engagement-in-15.jpg',
    width: 3000,
    height: 2000,
    title: 'Engagement Photo Session',
    description: 'Houghton, MI - September 2023',
  },
  { name: 'kitties.jpg', width: 1979, height: 960, gallerySkip: true },
  { name: 'hockey-collage.jpg', width: 3000, height: 2000, gallerySkip: true },
];

const photos = awsPhotos
  .map((photo) => {
    if (photo.gallerySkip) return null;
    const width = breakpoints[0];
    const height = (photo.height / photo.width) * width;

    return {
      src: awsLink(photo.name, width, height),
      width,
      height,
      title: photo.title,
      description: photo.description,
      srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((photo.height / photo.width) * breakpoint);
        return {
          src: awsLink(photo.name, breakpoint, height),
          width: breakpoint,
          height,
        };
      }),
    };
  })
  .filter((item) => !!item);

const manualPhoto = (width, photo) => {
  const height = (photo.height / photo.width) * width;

  return {
    src: awsLink(photo.name, width, height),
    width,
    height,
    srcSet: breakpoints.map((breakpoint) => {
      const height = Math.round((photo.height / photo.width) * breakpoint);
      return {
        src: awsLink(photo.name, breakpoint, height),
        width: breakpoint,
        height,
      };
    }),
  };
};

export { awsLink, awsPhotos, photos, manualPhoto };
