import { useEffect, useRef, useState } from 'react';

import { ArrowForward } from '@mui/icons-material';
import { Box, IconButton, Typography, styled, useTheme } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import { awsLink } from '../Gallery/photos';

const Container = styled(Box)(({ theme }) => ({
  '@keyframes fadeInAnim': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  width: '100vw',
  height: '100%',
  position: 'fixed',
  animation: 'fadeInAnim ease-in-out 1.25s',
  animationIterationCount: 1,
  animationFillMode: 'forwards',
}));

const Cover = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  console.log({theme});

  const carouselItemSxProps = (num) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    opacity: num > 1 ? 0 : 1,
    [theme.breakpoints.between('xs', 'md')]: {
      backgroundImage: `url(${awsLink(`carousel${num}_sm.jpg`, 825, 464)})`,
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${awsLink(`carousel${num}.jpg`, 1650, 928)})`,
    },
    transition: 'opacity 1000ms cubic-bezier(0.6, 0, 0.4, 1)',
  });

  const [activeSlide, setActiveSlide] = useState(Math.floor(Math.random() * 5));
  const interval = useRef();

  useEffect(() => {
    interval.current = setTimeout(() => {
      setActiveSlide((activeSlide + 1) % 5);
    }, 7500);
    return () => {
      clearTimeout(interval.current);
    };
  }, [activeSlide]);

  useEffect(() => {
    mixpanel.track('Cover load');
  }, []);

  return (
    <Container>
      {Array.from({ length: 5 }, (_, i) => i + 1).map((slideNum) => (
        <Box
          width="100%"
          height="100%"
          sx={{
            ...carouselItemSxProps(slideNum),
            opacity: activeSlide + 1 === slideNum ? 1 : 0,
          }}
        />
      ))}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'rgba(255,255,255,0.75)',
          opacity: 0.8,
          transform: 'translate(-50%, -50%)',
          padding: 1,
        }}
        minWidth={{ md: 505, lg: 'auto' }}
      >
        <Box sx={{ backgroundColor: 'white', padding: 4 }} textAlign="center">
          <Box mb={{ xs: 2, md: 1 }}>
            <Typography variant="h5" textTransform="uppercase">
              We're married!
            </Typography>
          </Box>
          <Typography variant="h2" lineHeight={{ xs: 1, md: 1.3 }} textTransform="none" fontFamily="Great Vibes">
            Brandon and Britta
          </Typography>
          <Typography variant="h5" fontWeight="bold" textTransform="uppercase">
            9.06.24
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          borderRadius: '50%',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: ({ palette }) => palette.primary.main,
          opacity: 0.8,
          padding: 0.5,
          transform: 'translate(-50%, -20px)',
        }}
      >
        <Box sx={{ backgroundColor: ({ palette }) => palette.primary.main, borderRadius: '50%' }}>
          <IconButton
            size="large"
            onClick={() => {
              console.log('ok');
              mixpanel.track('Cover Continue');
              navigate('/home');
            }}
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(105, 63, 108, 1)',
              },
            }}
          >
            <ArrowForward sx={{ color: ({ palette }) => palette.grey[50] }} />
          </IconButton>
        </Box>
      </Box>
    </Container>
  );
};

export default Cover;
