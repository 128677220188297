import React from 'react';

import { Box } from '@mui/material';

const HockeySticksPuckImage = () => (
  <Box width={50} mx="auto">
    <img src="./images/sticks-puck-icon.png" width="100%" alt="crossed hockey sticks with puck" />
  </Box>
);

export default HockeySticksPuckImage;
