import { DirectionsCar, FlightTakeoff, Hotel } from '@mui/icons-material';
import { Box, Button, Link, Typography } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { LOT_22_HREF, PARKING_IMAGE } from '../../../constants';
import linkTrack from '../../../utils/linkTrack';
import ContentPanel from '../../layout/ContentPanel';

const Travel = () => {
  return (
    <ContentPanel>
      <Box display="flex" flexDirection="column" alignItems="center" px={2}>
        <Hotel color="primary" sx={{ fontSize: 50 }} />
        <Box maxWidth={550} mt={2}>
          <Typography paragraph>
            We have two blocks reserved for the weekend of the wedding, these are both very close to the reception venue so
            no one has to worry about driving after a fun evening.
          </Typography>
          <Typography paragraph mb={4}>
            There is typically only one Uber/Lyft driver available in the area on a given day, so if you choose to stay
            elsewhere, transportation options may be limited.
          </Typography>
          <Typography variant="h6">Hampton Inn and Suites</Typography>
          <Typography variant="caption">Next door to Dee Stadium</Typography>
          <Typography paragraph mt={1}>
            820 Shelden Ave
            <br />
            Houghton, MI 49931
          </Typography>
          <Typography paragraph mt={1}>
            Online booking is only available for Friday night, if you would like to add additional nights, book online first,
            then call{' '}
            <a
              href="tel:+19064871700"
              onClick={() => {
                linkTrack('tel', { link: 'Hampton tel' });
                return true;
              }}
            >
              (906) 487-1700
            </a>{' '}
            and you can add nights at the block rate.
          </Typography>
          <Link href="https://group.hamptoninn.com/okiom2" target="_blank">
            <Button variant="contained" onClick={() => mixpanel.track('Hampton - Book Online')}>
              Book Online
            </Button>
          </Link>
          <Typography paragraph mt={1}>
            Rooms Available:
            <ul>
              <li>
                Standard two queen
                <ul>
                  <li>$200/night</li>
                </ul>
              </li>
              <li>
                Two queen water view
                <ul>
                  <li>$218/night</li>
                </ul>
              </li>
              <li>
                Standard king with sofa bed
                <ul>
                  <li>$218/night</li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Typography variant="h6">Super 8</Typography>
          <Typography variant="caption">Less than half mile walk via Waterfront Trail</Typography>
          <Typography paragraph mt={1}>
            1200 E Lakeshore Drive
            <br />
            Houghton, MI 49931
          </Typography>
          <Typography paragraph mt={1}>
            Call{' '}
            <a
              href="tel:+19064822240"
              onClick={() => {
                linkTrack('tel', { link: 'Super 8 tel' });
                return true;
              }}
            >
              (906) 482-2240
            </a>{' '}
            to book using “Anderson Wedding”. Booking online will not get the block rate or in our block of rooms.
          </Typography>
          <Typography paragraph>
            The below rates are available for any number of nights around the wedding weekend.
          </Typography>
          <Typography paragraph mt={1}>
            Rooms Available:
            <ul>
              <li>
                Single queen parking lot view
                <ul>
                  <li>$130/night</li>
                </ul>
              </li>
              <li>
                Single queen water view
                <ul>
                  <li>$134/night</li>
                </ul>
              </li>
              <li>
                Two queens parking lot view
                <ul>
                  <li>$134/night</li>
                </ul>
              </li>
              <li>
                Two queens water view
                <ul>
                  <li>$144/night</li>
                </ul>
              </li>
            </ul>
          </Typography>
        </Box>
        <DirectionsCar color="primary" sx={{ fontSize: 50 }} />
        <Box maxWidth={550} mt={2}>
          <Typography paragraph>
            Getting to Houghton is often a long trip, whether you drive or fly. Driving from downstate Michigan you can
            either drive north and over the Mackinac Bridge or drive south around Chicago and north through Wisconsin.
          </Typography>
          <Typography paragraph>
            Plenty of parking is available at the SDC for the ceremony,{' '}
            <a
              href={LOT_22_HREF}
              target="_blank"
              rel="noreferrer"
              onClick={() => linkTrack(LOT_22_HREF, { link: 'lot 22 maps' })}
            >
              lot 22
            </a>{' '}
            is the closest to the main entrance, and is free, open visitor parking. Signage will be in place at and around
            the main SDC entrance to direct guests where to go.
          </Typography>
          <Typography paragraph>
            If you are driving to the reception, plenty of city parking is available within a block of Dee Stadium, please
            reference{' '}
            <a
              href={PARKING_IMAGE}
              target="_blank"
              rel="noreferrer"
              onClick={() => linkTrack(PARKING_IMAGE, { link: 'parking image' })}
            >
              this image
            </a>{' '}
            for parking options.
          </Typography>
        </Box>
        <FlightTakeoff color="primary" sx={{ fontSize: 50 }} />
        <Box maxWidth={550} mt={2}>
          <Typography paragraph>
            The Houghton County Memorial Airport (CMX) is very small, with only two flights a day from Chicago. If you are
            interested in flying in, we also recommend looking at Ford Airport (IMT) in Iron Mountain or Sawyer International
            Airport (MQT) in Gwinn (outside of Marquette), which are both a two hour drive from Houghton. You'll just want to
            double check rental car availability as these are very small airports with limited rental options as well.
          </Typography>
          <Typography paragraph>
            Other options for flying include the following:
            <ul>
              <li>
                Rhinelander–Oneida County Airport (RHI) - Rhinelander, WI
                <ul>
                  <li>~2 and a half hour drive</li>
                </ul>
              </li>
              <li>
                Delta County Airport (ESC) - Escanaba, MI
                <ul>
                  <li>~3 hour drive</li>
                </ul>
              </li>
              <li>
                Green Bay Austin Straubel Int'l Airport (GRB) - Green Bay, WI
                <ul>
                  <li>~4 hour drive</li>
                </ul>
              </li>
            </ul>
          </Typography>
        </Box>
        <Typography variant="h4" mt={1}>
          Ceremony
        </Typography>
        <Typography paragraph mt={1} minWidth={250}>
          John MacInnes Student Ice Arena
          <br />
          600 MacInnes Drive
          <br />
          Houghton, Michigan 49931
        </Typography>
        <Typography variant="h4" mt={1}>
          Reception
        </Typography>
        <Typography paragraph mt={1} minWidth={250}>
          Dee Stadium
          <br />
          700 E Lakeshore Drive
          <br />
          Houghton, MI 49931
        </Typography>
      </Box>
    </ContentPanel>
  );
};

export default Travel;
